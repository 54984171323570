<template>
  <div class="indexReviewCarousel">
    <div class="indexReviewCarousel__wrapper">
      <div
          v-for="item in data"
          :key="item"
      >
        <IndexReviewCarouselItem
            :name="item.name"
            :image="item.image"
            :text="item.text"
        />
      </div>
    </div>
  </div>
</template>
<script>
import IndexReviewCarouselItem from "@/components/IndexReviewCarouselItem";
import {tns} from "tiny-slider";

export default {
  data() {
    return {
      carousel: null,
      data: [
        {
          name: 'Kamila Pilná',
          image: 'kamila-pilna.jpg',
          text: 'Pred časom som dostala od súčasného zamestnávateľa ponuku na povýšenie pod podmienkou, že si doplním vzdelanie a získam certifikát na MS Dynamics. Zapísala som sa do ITide.sk, prešla som si kurzy, dostala som nejaký čas na prípravu a urobila som si certifikát. Určite oceňujem to, že som si prehĺbila doterajšie znalosti a načerpala dosť tipov od profesionálov z Microsoftu. Za mňa jednoznačne super.',
        },
        {
          name: 'Petr Jaroš',
          image: 'petr-jaros.jpg',
          text: 'Doteraz som sa roky venoval realitám a obchodu, o IT som veľa nevedel. Kedysi som sa ale u nás vo firme zapojil do implementácie CRM a musím povedať, že ma to pomerne zaujalo. Chcel som sa kariérne posunúť, a tak som sa prihlásil do ITide.sk . Behom pár týždňov som si prešiel niekoľko kurzov a potom už ma konzultanti odporučili do firmy, kde teraz pracujem. Je zo mňa IT konzultant. Za mňa je to teda relatívne rýchla cesta, ako nasmerovať svoju kariéru do IT.',
        },
        {
          name: 'Karel Antoš',
          image: 'karel-antos.jpg',
          text: 'Vďaka ITide.sk sa mi podarilo získať pozíciu v jednej z popredných IT spoločností na trhu. Pomerne rýchlo som získal povedomie o MS Dynamics 365 a využití technológií v praxi, čo bolo pre mňa zásadné, pretože som predtým pôsobil v inom odbore.',
        },
        {
          name: 'Lada Kosová',
          image: 'lada-kosova.jpg',
          text: 'Často som počúvala, aký je IT lukratívny odbor. Hovorila som si, že by som do toho mohla ísť aj ja. Celkom ma vystrašili ceny klasických kurzov a zároveň som nemala na štúdium veľa času. Dopočula som sa o ITide.sk a skúsila som to. Baví ma na tom, že môžem študovať kedykoľvek, odkiaľkoľvek a vlastným tempom.',
        },
      ]
    }
  },
  mounted() {
    this.createCarousel();
  },
  methods: {
    createCarousel() {
      this.carousel = tns({
        container: '.indexReviewCarousel__wrapper',
        edgePadding: this.itemWidth,
        items: 1,
        gutter: 0,
        slideBy: 1,
        useLocalStorage: false,
        autoplay: false,
        autoplayHoverPause: true,
        autoplayTimeout: 3500,
        mouseDrag: true,
        center: true,
        controls: false,
        navPosition: 'bottom',
        responsive: {
          0: {
            edgePadding: 50,
            gutter: 30,
          },
          576: {
            edgePadding: 100,
            gutter: 70,
          },
          768: {
            edgePadding: 140,
          },
          992: {
            edgePadding: 300,
            gutter: 120,
          },
          1200: {
            edgePadding: 400,
          },
          1400: {
            edgePadding: 500,
          },
          1600: {
            edgePadding: 600,
          },
          1920: {
            edgePadding: this.countLargeWidth,
          }
        }
      });
    },
    destroyCarousel() {
      this.carousel.destroy();
    }
  },
  computed: {
    countLargeWidth() {
      return window.top.innerWidth * 35 / 100
    }
  },
  unmounted() {
    this.destroyCarousel();
  },
  components: {
    IndexReviewCarouselItem
  }
}
</script>