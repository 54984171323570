<script setup>
import BaseModal from "@/components/BaseModal.vue";
import {getCookie, setCookie} from "@/helpers/helpers.js";
import {ref} from "vue";

const isActive = ref(false)
const cookieName = 'security-22-05-2024'

// Wait to taimeout and open modal
if (!getCookie(cookieName)) {
  // wait 2s
  setTimeout(() => {
    // open modal
    isActive.value = true
  }, 2000)
}

/**
 * Handle close click
 * Set cookie
 * */
function handleClose() {
  // close modal
  isActive.value = false;

  // set cookie
  setCookie(cookieName, true, .25)
}

</script>

<template>
  <BaseModal
      id="uvodDoPowerPlatform"
      :is-active="isActive"
      :size="800"
      @dtc-modal-close="handleClose"
  >
    <div class="popup">
      <a href="https://goodcalleu.ubpages.com/itide-kurz-bezpecnost/" target="_blank">
        <img src="../assets/images/popup/bezpecnost2024_2.jpg"
             alt="Základy bezpečnosti"
             width="1200"
             height="628">
      </a>
    </div>
  </BaseModal>
</template>